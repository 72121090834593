<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px">
    <v-row no-gutters class="mx-2" v-if="isLoaded && impact">
      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <!-- Edit btn
          <v-btn
              icon
              class="btn-primary"
              color="primary"
              @click="setShowEditDialog(true)">
            <v-icon small>fas fa-pencil</v-icon>
          </v-btn-->
        </v-row>
      </v-col>

      <!-- Details -->
      <v-col cols="12">
        <v-row no-gutters></v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-2" v-else>
      <Skeleton :occurrence="1" type="card" />
    </v-row>

    <!-- Edit dialog -->
    <StudyDialog
      v-if="showDialog.edit || showDialog.add"
      :value="showDialog.edit || showDialog.add"
      :product="product"
      :item="showDialog.edit ? study : null"
      @close="(needRefresh) => setShowEditDialog(false, needRefresh)"
    />
  </v-card>
</template>

<script>
export default {
  name: "ProductTraceabilityTab",

  components: {
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    StudyDialog: () =>
      import("@/components/Studies/StudyDialog"),
  },
  mixins: [],

  props: {
    product: { type: Object, default: () => null },
    study: { type: Object, default: () => null },
  },

  data() {
    return {
      showDialog: {
        edit: false,
      },
      impact: null,
      isLoaded: false,
      isLoadingDelete: false,
    };
  },

  methods: {
    setShowEditDialog(val, needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.edit = val;
    },
    getImpact() {
      this.isLoaded = false;
      this.$http
        .get(`/studies/${this.study.id}/acv`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.impact = res.data;
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
  },

  watch: {
    study(newVal, oldVal) {
      if (newVal) this.getImpact();
    },
  },

  computed: {
    accountId() {
      if (this.product.account && this.product.account.id)
        return this.product.account.id;
      return this.product.account;
    },
    supplierId() {
      if (this.study.supplier && this.study.supplier.id)
        return this.study.supplier.id;
      return this.study.supplier;
    },
    supplierRef() {
      if (this.study.supplier) return this.study.supplier.ref;
      return null;
    },
    supplierFormURL() {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${this.supplierRef}`;
    },

    materialFormsURL() {
      return `${process.env.VUE_APP_IMPACT_FORMS_URL}/suppliers/${this.supplierRef}/materials`;
    },
  },
};
</script>

<style scoped></style>
